import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import FluidImage from "../components/FluidImage"

export const IndexTemplate = ({ image }) => (
  <>
    <div className="index__signature">
      <div className="index__signature--aspect">
        <div className="index__signature--mask">
          {image ? <FluidImage image={image} alt="" /> : null}
        </div>
      </div>
    </div>
  </>
)

const Index = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout className="index">
      <IndexTemplate image={frontmatter.image} />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query IndexTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
