import React from "react"
import Img from "gatsby-image/withIEPolyfill"

const FluidImage = ({ image, alt, contain = false }) => {
  if (image) {
    if (image.childImageSharp) {
      return (
        <Img
          fluid={image.childImageSharp.fluid}
          alt={alt ? alt : ""}
          objectFit={contain ? "contain" : "cover"}
        />
      )
    } else {
      return (
        <img
          src={image}
          alt={alt ? alt : ""}
          style={{ objectFit: contain ? "contain" : "cover" }}
        />
      )
    }
  } else {
    return <></>
  }
}

export default FluidImage
